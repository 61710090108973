<template>
  <article class="grid grid-cols-1 lg:grid-cols-10 gap-5 lg:border-t last:lg:border-b border-black/7 lg:px-7 lg:py-12 items-center">
    <UtilityImage
      v-if="article.image"
      :src="article.image?.formats?.medium?.url || article.image?.url"
      :alt="article.image?.alternativeText || article.image?.name"
      image-class="object-contain"
      class="w-full lg:w-40 lg:col-span-2"
    />
    <div :class="{ 'lg:col-span-7': !article.image, 'lg:col-span-5': article.image }">
      <Heading :text="article.heading" :size="3" />
      <p class="pt-2 text-sm text-grey-light" v-html="text"></p>
    </div>
    <UtilityButton
      :link="`/${article.category?.url || ArticleCategory.blog}/${article.url}`"
      class="lg:col-span-3 block mr-auto lg:ml-auto lg:mr-0"
    >
      {{ showMoreText }}
    </UtilityButton>
  </article>
</template>
<script setup lang="ts">
import { ArticleCategory } from '~/utils/types'

const excerpt = useExcerpt()
const props = defineProps({
  article: {
    type: Object,
    required: true,
  },
  showMoreText: {
    type: String,
    default: 'Čítať ďalej',
  },
  excerptCount: {
    type: Number,
    default: 25,
  },
})

const text = excerpt(props.article.text, props.excerptCount)
</script>
